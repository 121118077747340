<template>
    <div>
        <div class="operations-expand-block-name">Operation Versions</div>
        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid"
            border
            round>
                <vxe-table-column field="opsubid" title="Version" width="70"></vxe-table-column>
                <vxe-table-column field="status" title="Status" width="100"></vxe-table-column>
                <vxe-table-column field="cdt" title="Create Date" width="200" formatter="formatDateTime"></vxe-table-column>
                <vxe-table-column title="Changes">
                    <template v-slot="{ row }">
                        <div v-for="change in row.changes">
                            {{change[0]}} {{make_field_name(change[1])}} {{make_change_val(change[0],change[2])}}
                        </div>
                    </template>
                </vxe-table-column>
        </vxe-table>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            required: true,
            type: Array
        }
    },

    methods: {
        make_field_name(data){
            if (typeof data === 'string'){
                return data;
            }
            else {
                return data[0] + '[' + (Number(data[1]) + 1) + ']' + ' ' + data[2]
            }
        },

        make_change_val(change_type, data){
            if (typeof data[0] === 'object'){
                return data
            }
            else {
                return data[0] + ' -> ' + data[1]
            }
        }
    }
}
</script>
